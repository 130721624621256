export const GET_COURSE_ADMIN = "GET_COURSE_ADMIN";
export const GET_COURSE_ADMIN_START = "GET_COURSE_ADMIN_START";
export const GET_COURSE_ADMIN_SUCCESS = "GET_COURSE_ADMIN_SUCCESS";
export const GET_COURSE_ADMIN_FAIL = "GET_COURSE_ADMIN_FAIL";

export const SAVE_COURSE_ADMIN = "SAVE_COURSE_ADMIN";
export const SAVE_COURSE_ADMIN_START = "SAVE_COURSE_ADMIN_START"
export const SAVE_COURSE_ADMIN_SUCCESS = "SAVE_COURSE_ADMIN_SUCCESS"
export const SAVE_COURSE_ADMIN_FAIL = "SAVE_COURSE_ADMIN_FAIL"

export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_START = "DELETE_COURSE_START";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";

export const UPDATE_COURSE_ADMIN = "UPDATE_COURSE_ADMIN";
export const UPDATE_COURSE_ADMIN_START = "UPDATE_COURSE_ADMIN_START";
export const UPDATE_COURSE_ADMIN_SUCCESS = "UPDATE_COURSE_ADMIN_SUCCESS";
export const UPDATE_COURSE_ADMIN_FAIL = "UPDATE_COURSE_ADMIN_FAIL"

export const GET_MODULE_DETAILS_ADMIN = "GET_MODULE_DETAILS_ADMIN";
export const GET_MODULE_DETAILS_ADMIN_START = "GET_MODULE_DETAILS_ADMIN_START";
export const GET_MODULE_DETAILS_ADMIN_FAIL = "GET_MODULE_DETAILS_ADMIN_FAIL";
export const GET_MODULE_DETAILS_ADMIN_SUCCESS = "GET_MODULE_DETAILS_ADMIN_SUCCESS"

export const SAVE_ADMIN_MODULE = "SAVE_ADMIN_MODULE";
export const SAVE_ADMIN_MODULE_START = "SAVE_ADMIN_MODULE_START"
export const SAVE_ADMIN_MODULE_SUCCESS = "SAVE_ADMIN_MODULE_SUCCESS";
export const SAVE_ADMIN_MODULE_FAIL = "SAVE_ADMIN_MODULE_FAIL";

export const UPDATE_ADMIN_MODULE = "UPDATE_ADMIN_MODULE";
export const UPDATE_ADMIN_MODULE_START = "UPDATE_ADMIN_MODULE_START"
export const UPDATE_ADMIN_MODULE_FAIL = "UPDATE_ADMIN_MODULE_FAIL";
export const UPDATE_ADMIN_MODULE_SUCCESS = "UPDATE_ADMIN_MODULE_SUCCESS";

export const DELETE_ADMIN_MODULE = "DELETE_ADMIN_MODULE";
export const DELETE_ADMIN_MODULE_START = "DELETE_ADMIN_MODULE_START"
export const DELETE_ADMIN_MODULE_SUCCESS = "DELETE_ADMIN_MODULE_SUCCESS";
export const DELETE_ADMIN_MODULE_FAIL = "DELETE_ADMIN_MODULE_FAIL";


export const GET_ADMIN_QUESTION = "GET_ADMIN_QUESTION";
export const GET_ADMIN_QUESTION_START = "GET_ADMIN_QUESTION_START";
export const GET_ADMIN_QUESTION_FAIL = "GET_ADMIN_QUESTION_FAIL";
export const GET_ADMIN_QUESTION_SUCCESS = "GET_ADMIN_QUESTION_SUCCESS";

export const SAVE_ADMIN_QUESTION = "SAVE_ADMIN_QUESTION";
export const SAVE_ADMIN_QUESTION_START = "SAVE_ADMIN_QUESTION_START";
export const SAVE_ADMIN_QUESTION_FAIL = "SAVE_ADMIN_QUESTION_FAIL";
export const SAVE_ADMIN_QUESTION_SUCCESS = "SAVE_ADMIN_QUESTION_SUCCESS";

export const UPDATE_ADMIN_QUESTION = "UPDATE_ADMIN_QUESTION";
export const UPDATE_ADMIN_QUESTION_START = "UPDATE_ADMIN_QUESTION_START";
export const UPDATE_ADMIN_QUESTION_FAIL = "UPDATE_ADMIN_QUESTION_FAIL";
export const UPDATE_ADMIN_QUESTION_SUCCESS = "UPDATE_ADMIN_QUESTION_SUCCESS";

export const DELETE_ADMIN_QUESTION = "DELETE_ADMIN_QUESTION";
export const DELETE_ADMIN_QUESTION_START = "DELETE_ADMIN_QUESTION_START";
export const DELETE_ADMIN_QUESTION_FAIL = "DELETE_ADMIN_QUESTION_FAIL";
export const DELETE_ADMIN_QUESTION_SUCCESS = "DELETE_ADMIN_QUESTION_SUCCESS"

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const GET_ADMIN_QUARDINATE = "GET_ADMIN_QUARDINATE";
export const GET_ADMIN_QUARDINATE_START = "GET_ADMIN_QUARDINATE_START";
export const GET_ADMIN_QUARDINATE_FAIL = "GET_ADMIN_QUARDINATE_FAIL";
export const GET_ADMIN_QUARDINATE_SUCCESS = "GET_ADMIN_QUARDINATE_SUCCESS";

export const SAVE_ADMIN_QUARDINATE = "SAVE_ADMIN_QUARDINATE";
export const SAVE_ADMIN_QUARDINATE_START = "SAVE_ADMIN_QUARDINATE_START";
export const SAVE_ADMIN_QUARDINATE_FAIL = "SAVE_ADMIN_QUARDINATE_FAIL";
export const SAVE_ADMIN_QUARDINATE_SUCCESS = "SAVE_ADMIN_QUARDINATE_SUCCESS";

export const UPDATE_ADMIN_QUARDINATE = "UPDATE_ADMIN_QUARDINATE";
export const UPDATE_ADMIN_QUARDINATE_START = "UPDATE_ADMIN_QUARDINATE_START";
export const UPDATE_ADMIN_QUARDINATE_FAIL = "UPDATE_ADMIN_QUARDINATE_FAIL";
export const UPDATE_ADMIN_QUARDINATE_SUCCESS = "UPDATE_ADMIN_QUARDINATE_SUCCESS";

export const DELETE_ADMIN_QUARDINATE = "DELETE_ADMIN_QUARDINATE";
export const DELETE_ADMIN_QUARDINATE_START = "DELETE_ADMIN_QUARDINATE_START";
export const DELETE_ADMIN_QUARDINATE_FAIL = "DELETE_ADMIN_QUARDINATE_FAIL";
export const DELETE_ADMIN_QUARDINATE_SUCCESS = "DELETE_ADMIN_QUARDINATE_SUCCESS"

export const SAVE_ADMIN_CENTRE="SAVE_ADMIN_CENTRE"
export const SAVE_ADMIN_CENTRE_START="SAVE_ADMIN_CENTRE_START"
export const SAVE_ADMIN_CENTRE_SUCCESS="SAVE_ADMIN_CENTRE_SUCCESS"
export const SAVE_ADMIN_CENTRE_FAIL="SAVE_ADMIN_CENTRE_FAIL"

export const DELETE_ADMIN_CENTRE="DELETE_ADMIN_CENTRE"
export const DELETE_ADMIN_CENTRE_START="DELETE_ADMIN_CENTRE_START"
export const DELETE_ADMIN_CENTRE_SUCCESS="DELETE_ADMIN_CENTRE_SUCCESS"
export const DELETE_ADMIN_CENTRE_FAIL="DELETE_ADMIN_CENTRE_FAIL"

export const UPDATE_ADMIN_CENTRE="UPDATE_ADMIN_CENTRE"
export const UPDATE_ADMIN_CENTRE_START="UPDATE_ADMIN_CENTRE_START"
export const UPDATE_ADMIN_CENTRE_SUCCESS="UPDATE_ADMIN_CENTRE_SUCCESS"
export const UPDATE_ADMIN_CENTRE_FAIL="UPDATE_ADMIN_CENTRE_FAIL"

// export const GET_ADMIN_CENTRE="GET_ADMIN_CENTRE"
// export const GET_ADMIN_CENTRE_START="GET_ADMIN_CENTRE_START"
// export const GET_ADMIN_CENTRE_SUCCESS="GET_ADMIN_CENTRE_SUCCESS"
// export const GET_ADMIN_CENTRE_FAIL="GET_ADMIN_CENTRE_FAIL"


//Authentication
export const LOGIN="LOGIN";
export const LOGIN_START="LOGIN_START";
export const LOGIN_FAIL="LOGIN_FAIL";
export const LOGIN_SUCCESS="LOGIN_SUCCESS"; 

export const GET_USER="GET_USER";
export const GET_USER_START="GET_USER_START";
export const GET_USER_SUCCESS="GET_USER_SUCCESS";
export const GET_USER_FAIL="GET_USER_FAIL";

export const SET_USER_SUCCESS="SET_USER_SUCCESS";


