import React from 'react'

const cusetomStyle = () => {
 return {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
        // top: '70px',
        // left: '270px',
        // right: '70px',
        // // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
        // marginTop: "100px"
    }
 }
}

export default cusetomStyle